<template>
  <div>
        <nav class="level mx-5 mt-5 mb-4">
            <div class="level-left">
              <a @click="$router.go(-1)" class="level-item">
                <b-icon type="is-grey-darker" icon="arrow-left"></b-icon>
              </a>
                <div class="level-item">
                  <p v-if="mapitem" class="subtitle is-5">
                    <strong>{{ mapitem.name }}</strong>
                  </p>
                </div>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <b-tag>Draft</b-tag>
                </div>
            </div>
        </nav>
      <hr class="mb-1">

        <b-tabs class="px-2 pb-2"  >
          <b-tab-item label="Details">
            <b-field label="Device type">
              <b-select v-model="device.category" placeholder="Select type" expanded>
                <option v-for="category in categories" v-bind:key="category.id" :value="category">
                  {{category.name}}
                </option>
              </b-select>
            </b-field>
            <b-field label="Device location">
              <b-input v-model="device.location"></b-input>
            </b-field>
            <b-field label="Comment">
              <b-input v-model="device.comment" maxlength="200" type="textarea"></b-input>
            </b-field>
            <div class="buttons">
              <b-button type="is-info" v-on:click="updateDevice" expanded>Update Device</b-button>
            </div>
          </b-tab-item>

          <b-tab-item label="Activity">
            Lorem <br>
            ipsum <br>
            dolor <br>
            sit <br>
            amet.
          </b-tab-item>

          <b-tab-item label="Hierarchy">
            Lorem <br>
            ipsum <br>
            dolor <br>
            sit <br>
            amet.
          </b-tab-item>

        </b-tabs>

    </div>
</template>
<script>

import CardComponent from '@/components/CardComponent'
import axios from 'axios'

export default {
  name: 'MapItemDetailSidebar',
  props: {
    selected: {
      default: null
    },
    visible: {
      default: true
    },
    isCreating: {
      default: true
    }
  },
  components: {
    CardComponent
  },
  data () {
    return {
      device: {
      },
      categories: []
    }
  },
  computed: {
    mapitem() {
      return this.$store.getters["deplo/getMapItem"](this.$route.params.id)
    }

  },
  mounted () {
    //this.fetchCategories()
  },
  watch: {
    selected: function () {
      console.log('Changed')
      if (this.selected.features.length > 1) {
        this.visible = false
        this.device = {}
      } else if (this.selected.features.length === 0) {
        this.visible = false
        this.device = {}
      } else if (this.isCreating) {
        console.log('tis een nieuwe')
        this.visible = true
      } else {
        this.fetchDevice(this.selected)
        this.visible = true
      }
    }
  },
  methods: {

    async updateDevice () {
      let thisIns = this
      await axios.patch('http://localhost:8000/devices/' + thisIns.device.id + '/', {
        category: thisIns.device.category.id,
        location: thisIns.device.location,
        comment: thisIns.device.comment,
        geom: thisIns.device.geom
      })
        .then(function (response) {
          thisIns.device = response.data
          thisIns.$emit('deviceUpdated', thisIns.device.id)
          thisIns.$buefy.snackbar.open({
            message: 'Device ' + thisIns.device.id + ' Updated',
            queue: false
          })
          thisIns.visible = false
        })
        .catch((error) => {
          thisIns.$buefy.toast.open({
            message: `Error: ${error.message}`,
            type: 'is-danger',
            position: 'is-bottom',
            queue: false
          })
        })
    },
    async fetchCategories () {
      let thisIns = this
      await axios.get('http://localhost:8000/categories')
        .then(function (response) {
          thisIns.categories = response.data
        })
        .catch((error) => {
          thisIns.$buefy.toast.open({
            message: `Error: ${error.message}`,
            type: 'is-danger',
            position: 'is-bottom',
            queue: false
          })
        })
    }
  }
}
</script>

<style scoped>

</style>
