<template>
  <div>
    <nav class="level mx-5 mt-5 mb-4">
      <div class="level-left">
        <div class="level-item">
          <p class="subtitle is-5">
            <strong>MapItems</strong> {{ this.device.id }}
          </p>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-button size="is-small" @click="$router.push({name: 'mapitems.detail', params: {id: 3}})">View device 3
          </b-button>
        </div>
      </div>
    </nav>
    <hr class="mb-1">

    <div class="mx-4 my-4">
      <b-field>
        <b-input placeholder="Search..."
                 type="search"
                 icon="magnify"
                 icon-clickable
                 >
        </b-input>
      </b-field>

      <div class="content">
        <ul id="myUL">
          <li><span class="caret">Beverages</span>
            <ul class="nested">
              <li>Water</li>
              <li>Coffee</li>
              <li><span class="caret">Tea</span>
                <ul class="nested">
                  <li>Black Tea</li>
                  <li>White Tea</li>
                  <li><span class="caret">Green Tea</span>
                    <ul class="nested">
                      <li>Sencha</li>
                      <li>Gyokuro</li>
                      <li>Matcha</li>
                      <li>Pi Lo Chun</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>





  </div>
</template>
<script>

import axios from 'axios'

export default {
  name: 'MapItemOverviewSidebar',
  props: {
    selected: {
      default: null
    },
    visible: {
      default: true
    },
    isCreating: {
      default: true
    }
  },
  components: {},
  data() {
    return {
      device: {},
      categories: []
    }
  },
  mounted() {
  },
  watch: {
    selected: function () {
      console.log('Changed')
      if (this.selected.features.length > 1) {
        this.visible = false
        this.device = {}
      } else if (this.selected.features.length === 0) {
        this.visible = false
        this.device = {}
      } else if (this.isCreating) {
        console.log('tis een nieuwe')
        this.visible = true
      } else {
        this.fetchDevice(this.selected)
        this.visible = true
      }
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
